@import "utils/variables.scss";
body {
  overflow-x: hidden;
  font-family: 'TradeGothicLTStd';
}
body.ready {
}
.flex-center {
  justify-content: center;
}
.body {
  position:relative;
}
.spacer {
    height:50px;
}
.spacer-mini {
    height:10px;
}
#root {
  position:relative;
}
a {
    text-decoration: none;
    color: $vert;
    &:visited {
        color: $vert;
    }
    &:hover, &:active, &:focus {
        color: $eau;
    }
}
.routes-container {
    position:relative;
}
.bottom-padding {
  height:40vw;
  .biocybele & {
    height:73vw;
  }
  .vin & {
    height:31vw;
  }
  .noctambio & {
    height:31vw;
  }
}
.subroutes {
  position:relative;
  &>div {
    position:relative;
  }
}
.differentrub {
  & .fade-enter, .fade-enter-active {
    opacity: 0;
    height:0;
    overflow:hidden;
  }
  .loaded & .fade-enter-active {
    opacity: 1;
    height:auto;
    transition: opacity 500ms;
  }
  & .fade-exit, {
    opacity: 0;
  }
  .loaded & .fade-exit-active {
    opacity: 0.1;
    transition: opacity 500ms;
  }
}
.samerub {
  & .fade-enter, .fade-enter-active {
    opacity: 0;
    height:0;
    overflow:hidden;
  }
  .loaded & .fade-enter-active {
    opacity: 1;
    height:auto;
    transition: opacity 1ms;
  }
  & .fade-exit, .fade-exit-active {
    opacity: 1;
  }
  .loaded & .fade-exit-active {
    opacity: 0;
    transition: opacity 1ms;
  }
}

.settings-container {
  opacity:0;
  transition: opacity 0.5s;
  &.ready {
    opacity:1;
  }
}
@keyframes tremolo {
  from { opacity: 0; }
  to   { opacity: 1; }
}
.wait {
  position:fixed;
  top:0;
  left:0;
  width:100vw;
  height:110vh;
  opacity:1;
  transition: opacity 0.5s;
  display: flex;
  justify-content: center;
  &.ready {
    opacity:0;
  }
  & .logo {
      animation: 2s ease-in 0.2s infinite alternate both running tremolo;
      position:relative;
      align-self:center;
      height:350px;
      width:350px;
      @include md {
         transform: scale(0.8) translateY(20px);
      }
      &>span {
        position:absolute;
        top:-30px;
        left:0;
        width:100%;
        text-align:center;
        font-family: 'TradeGothicLTStd-Bold';
        font-size:20px;
      }
      & .logo-img {
          position:absolute;
          top:0;
          left:70px;
          height:350px;
          width:210px;
          background: url('./imgs/logo.svg');
          background-position: center 15px;
          background-repeat: no-repeat;
          background-size: contain;
          & span {
            position:absolute;
            bottom:0;
            left:0;
            width:100%;
            text-align:center;
            font-family: 'TradeGothicLTStd-Bold';
            color:#a32f34ff;
            font-size:45px;
          }
      }
  }
}
iframe {
    max-width:100%;
}
hr {
  border: 1px solid #FFF2;
}
