@import "../../utils/variables.scss";
.footer {
  position:relative;
  width:100%;
  padding-top:50px;
  background-color: $vert;
  & h3 {
    font-family:'Oleo Script';
    text-align: center;
    font-weight:normal;
    font-size:30px;
    line-height:1em;
    color:#FFF;
  }
  & h4 {
    font-family:'Oleo Script';
    text-align: center;
    font-weight:normal;
    font-size:25px;
    line-height:1em;
    color:#FFF8;
    margin:15px;
  }
  & .footer-partenaire {
    position: relative;
    & .footer-partenaire-nom {
      font-family:'TradeGothicLTStd';
      font-weight:normal;
      font-size:18px;
      line-height:1em;
      color:#FFF;
      text-align: center;
      margin:15px 0;
    }
    & .footer-partenaire-logo {
      position: relative;
      width:100%;
      padding-bottom:50%;
      & img {
        position: absolute;
        width:100%;
        height:100%;
        top:0;
        left:0;
        object-fit: contain;
      }
    }
  }
  & .footer-plan {
    position: relative;
    a {
      color: #FFFA;
    }
    a:hover, a:active, a:focus, a:visited {
      color: #FFF;
    }
    & .footer-plan-menu-titre {
      font-family:'Oleo Script';
      font-weight:normal;
      font-size:20px;
      line-height:1em;
      color:#FFF;
      margin:5px 0;
      a {
        color: #FFFE;
      }
      a:hover, a:active, a:focus, a:visited {
        color: #FFF;
      }
      &.center {
        text-align:center;
      }
    }
    & ul {
      margin:0;
      list-style: none;
      padding-left: 0;
    }
    & li {
      font-family:'Oleo Script';
      font-size:18px;
      line-height: 1.1;
      color:#fffc;
      padding:5px 0;
    }
  }
  & .footer-evenement {
    position: relative;
    a {
      color: #FFFE;
    }
    a:hover, a:active, a:focus, a:visited {
      color: #FFF;
    }
    & .footer-evenement-titre {
      font-family:'Oleo Script';
      font-weight:normal;
      font-size:25px;
      line-height:1em;
      color:#FFF;
      text-align: center;
      margin:15px 0;
    }
    & .footer-evenement-logo {
      position: relative;
      width:70%;
      margin:30px 15%;
    }
  }
  & .footer-contacts {
    font-family:'TradeGothicLTStd';
    position: relative;
    font-weight:normal;
    font-size:25px;
    color:#FFF;
    text-align: center;
    & h3 {
      font-family:'Oleo Script';
    }
    a {
      color: #FFFA;
    }
    a:hover, a:active, a:focus, a:visited {
      color: #FFF;
    }
  }
  & .footer-mentions {
    position: relative;
    font-weight:normal;
    font-size:14px;
    color:#FFF;
    text-align: left;
    font-family:'TradeGothicLTStd';
    & h3 {
      font-size:18px;
      font-family:'Oleo Script';
      text-align: left;
      &>span {
        position:relative;
        top:8px;
        cursor:pointer;
      }
    }
    a {
      color: #FFFA;
    }
    a:hover, a:active, a:focus, a:visited {
      color: #FFF;
    }
    & .mentions-content {
      height:0;
      opacity:0;
      transition: opacity 0.5s;
      overflow: hidden;
      &.show {
        height:auto;
        opacity:1;
      }
    }
  }
}
