.cartouche-image {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.cartouche-image-texte {
    position:absolute;
    width:100%;
    bottom:15px;
    background: #FFFD;
}
.cartouche-image-texte-inner {
    padding:15px 3% 10px 3%;
    text-align:center;
    font-family:'TradeGothicLTStd-Bold';
    color:#016a66;
    font-size:24px;
}
