@import "../../utils/variables.scss";
.bloc-diaporama {
  width:100%;
  padding-bottom:56.25%;
  position:relative;
  background-color:#FFF8;
  box-shadow: -6px 6px 5px #0000001F;
  & .swiper-container {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    & .swiper-slide {
      display:flex;
      align-items:center;
      & img {
        align-self:center;
        max-height:100%;
        margin:0 auto;
      }
    }
  }
  & .swiper-button-prev, & .swiper-button-next, & .swiper-pagination, & .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    opacity:1;
    transition: opacity 0.5s;
  }
  & .swiper-button-prev, & .swiper-button-next {
    color: $blanc;
    &:after {
      text-shadow: 0px 0px 10px #0008;
    }
  }
  & .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    opacity:0;
    color: #FFF;
  }
  & .swiper-pagination-bullet {
    opacity:1;
    background-color: #FFF;
    box-shadow: 0 0 10px #0005;
  }
  & .swiper-pagination-bullet-active {
    background-color: $vert;
  }

}
.bloc-texteimage {
  & img {
    box-shadow: -6px 6px 5px #0000001F;
  }
  &.texteimage-left .image {
    padding-right: 8%;
    @include sm {
      padding-right: 8px;
    }
  }
  &.texteimage-right .image {
    padding-left: 8%;
    @include sm {
      padding-left:8px;
    }
  }
}
.bloc-images {
  & .images-container {
    display: flex;
    justify-content: center;
  }
  & img {
    box-shadow: -6px 6px 5px #00000035;
  }
}
.bloc-separateur {
  height:50px;
  background-image: url('../../imgs/separateur.svg');
  background-position: center;
  background-repeat: no-repeat;
}
.bloc {
    margin-top:30px;
    font-family:'TradeGothicLTStd';
    color:$text;
    font-size:18px;
    line-height:1.5em;
    @include xs {
      font-size:15px;
    }
    & h1 {
        font-family:'Oleo Script';
        color:$h1;
        font-size:40px;
        line-height:1em;
        margin-top:0;
        font-weight: normal;
        @include xl {
          font-size:35px;
        }
        @include md {
          font-size:30px;
        }
        @include xs {
          font-size:25px;
        }
    }
    & h2 {
        font-family:'Oleo Script';
        color:$h2Bis;
        font-size:30px;
        line-height:1em;
        margin-top:0;
        font-weight: normal;
        @include xl {
          font-size:28px;
        }
        @include md {
          font-size:26px;
        }
        @include sm {
          font-size:24px;
        }
        @include xs {
          font-size:20px;
        }
    }
    & h3 {
        font-family:'TradeGothicLTStd-BdCn20';
        color:$h3;
        line-height:1em;
        font-size:20px;
        font-weight:800;
        @include xs {
          font-size:18px;
        }
    }
    & .bloc-documents-label {
        font-weight:800;
    }
}
