@import "../../utils/variables.scss";
.cartouche-agenda {
  position:-webkit-sticky;
  position:sticky;
  top: -150px;
  right: 0;
  height:0;
  z-index:2;
  transform: translateY(0) scale(1);
  transform-origin: right top;
  transition: top 0.8s cubic-bezier(0.33, 1, 0.68, 1), transform 0.5s cubic-bezier(0.33, 1, 0.68, 1);
  & .cartouche-agenda-inner {
    position:absolute;
    top:130px;
    @include lg {
        top:80px;
    }
    @include md {
        top:100px;
    }
    @include sm {
        top:270px;
    }
    @include xs {
        top:250px;
    }
    right:0;
    width:600px;
    height:800px;
    transition: height 1ms;
    overflow:hidden;
    & .disque1 {
        position:absolute;
        height:580px;
        width:580px;
        top:100px;
        right:-140px;
        background: #d9872bff;
        border-radius: 290px;
        box-shadow: 0 0 100px #0003;
    }
    & .disque2 {
        position:absolute;
        height:550px;
        width:550px;
        top:107px;
        right:-131px;
        background: #ffff;
        border-radius: 275px;
    }
    & .content {
        position:absolute;
        height:550px;
        width:550px;
        top:107px;
        right:30px;
        text-align: right;
        overflow: hidden;
        & h3 {
            font-family:'Oleo Script';
            font-weight:normal;
            color: #a32f34ff;
            font-size:45px;
            line-height: 1;
            margin: 15px 0;
        }
        & h3.event-title {
            font-family:'Oleo Script';
            font-weight:normal;
            color: #a32f34ff;
            font-size:30px;
            line-height: 1;
            margin: 0 0 5px 0;
            @include line-clamp(3, 1em);
        }
        & .event-dates {
            font-family:'TradeGothicLTStd-Bold';
            font-size:16px;
            line-height: 1;
            margin: 15px 0 0 0;
        }
        .event-texte {
          @include line-clamp(4, 1.25em);
          margin: 5px 0;
        }
    }
    & .shape-mask {
        float: left;
        height:550px;
        width:550px;
        top:7px;
        right:0;
        shape-outside: polygon(-28px -23px, -13px 572px, 99.62% 104.13%, 104.68% 89.30%, 89.04% 95.72%, 68.9% 95.65%, 50.64% 86.22%, 39.94% 73.4%, 33.61% 57.62%, 34.28% 40.24%, 41.24% 23.9%, 53.34% 11.73%, 70.89% 3.78%, 86.13% 3.6%, 103.33% 7.77%, 547px -38px);
    }
    & .evt {
        max-height:300px;
        transition:max-height 1s, opacity 0.8s;
        & .event-dates {
          margin:0;
        }
        &.hide {
          max-height:0;
          opacity:0;
        }
      }
  }
}
.cartouche-agenda {
  top:-150px;
  @include lg {
      top:-90px;
      transform: translateY(0) scale(0.9);
  }
  @include md {
      top:-90px;
      transform: translateY(0) scale(0.8);
  }
  @include sm {
      top:-300px;
      transform: translateY(0) scale(1);
  }
  @include xs {
      top:-410px;
      transform: translateY(0) scale(0.8);
  }
}
.cartouche-agenda.small, .cartouche-agenda.fixed {
    transform:translateY(calc(100vh - 88px)) scale(0.7);
    & .cartouche-agenda-inner {
      transition: height 1ms 0.8s;
      height:210px;
    }
    & .evt {
      opacity:0;
    }
    @include lg {
        transform:translateY(calc(100vh - 55px)) scale(0.5);
        & .cartouche-agenda-inner {
          transition: height 1ms 0.8s;
          height:210px;
        }
    }
    @include md {
        transform:translateY(calc(100vh - 65px)) scale(0.5);
        & .cartouche-agenda-inner {
          transition: height 1ms 0.8s;
          height:210px;
        }
    }
    @include sm {
        transform:translateY(calc(100vh + 60px)) scale(0.5);
    }
    @include xs {
        transform:translateY(calc(100vh + 180px)) scale(0.5);
    }
}
.cartouche-agenda.fixed {
    position:fixed;
}
.cartouche-agenda.hide {
  opacity:0;
}
