@import '../../utils/variables.scss';

.mention-layout {
    width:100%;
    padding-bottom: 57%;
    position:relative;
}
.mention-layout-inner {
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    perspective: 500px;
    perspective-origin: 25% 50%;
}
.mention-layout-0 {
    position:absolute;
    top:10%;
    right:5%;
    width:35%;
    height:80%;
    font-family:'TradeGothicLTStd';
    color:$gris;
    font-size:30px;
    hyphens: auto;
    text-align:center;
}
.mention-layout-1 {
    position:absolute;
    top:10%;
    right:45%;
    width:25%;
    height:40%;
    @include highlight-tile;
}
.mention-layout-2 {
    position:absolute;
    top:10%;
    right:72.5%;
    width:25%;
    height:35%;
    @include highlight-tile;
}
.mention-layout-3 {
    position:absolute;
    top:50%;
    right:77.5%;
    width:17%;
    height:40%;
    @include highlight-tile;
}
.mention-layout-4 {
    position:absolute;
    top:55%;
    right:50%;
    width:25%;
    height:30%;
    @include highlight-tile;
}
.cartouche-carte {
    width:100%;
    height:100%;
    position:absolute;
    left:0;
    top:0;
}
.dessin-carte {
    background:url('./dessin_carte.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width:47%;
    height:300%;
    position:absolute;
    top:-130%;
    right:0;
}
.cartouche-carte-textes {
    width:60%;
    height:135%;
    bottom:-80px;
    left:0;
    text-align:right;
    & .shape-mask {
      float: right;
      width:100%;
      height:100%;
      shape-outside: polygon(103.11% 64.6%, 94.49% 47.19%, 89.99% 33.37%, 87.14% 18.62%, 85.15% -7.76%, 103.29% -15.33%);
    }
}
.cartouche-carte-texte1 {
    font-family:'Oleo Script';
    font-weight:normal;
    color:#a32f34ff;
    font-size:50px;
    line-height:1;
}
.cartouche-carte-texte2 {
    font-family:'Oleo Script';
    font-weight:normal;
    color:#87a464ff;
    font-size:36px;
}
