@import "../../utils/variables.scss";
.accueil {
    font-family:'TradeGothicLTStd';
    position:relative;
    width:100%;
}
.accueil .diaporama {
  width:100%;
  padding-bottom:16.77%;
  @include sm {
    padding-bottom:25%;
  }
  @include xs {
    padding-bottom:30%;
  }

  position:relative;
  & .swiper-container {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    & .swiper-slide {
      & img {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
      & .slide-titre {
        position:absolute;
        bottom:0;
        left:0;
        font-family:'Oleo Script';
        font-weight:normal;
        font-size:40px;
        color:#016a66;
        background-color: #FFFA;
        padding:15px 30px;
        border-radius: 0 15px 0 0;
        white-space: nowrap;
        max-width:calc(100% - 30px);
        text-overflow: ellipsis;
        overflow:hidden;
        @include md {
          font-size:35px;
          padding:10px 20px;
        }
        @include sm {
          font-size:30px;
          padding:10px 20px;
        }
        @include xs {
          font-size:20px;
          padding:5px 10px;
        }
      }
    }
    & .swiper-pagination-bullet {
      opacity:1;
      background-color: #FFF;
      box-shadow: 0 0 10px #0005;
    }
    & .swiper-pagination-bullet-active {
      background-color: $vert;
    }

  }
}
.jolie-phrase {
    font-family:'Oleo Script';
    font-weight:normal;
    color:#016a66;
    font-size:45px;
    line-height: 1.5;
    text-align:center;
    margin:80px 500px 100px 100px;
    display: flex;
    justify-content: center;
    @include lg {
        font-size:35px;
        margin:80px 500px 120px 100px;
    }
    @include md {
        font-size:35px;
        margin:120px 400px 100px 50px;
    }
    @include sm {
        font-size:35px;
        margin:30px 30px 550px 30px;
    }
    @include xs {
        font-size:25px;
        margin:15px 15px 470px 15px;
    }
}
.jolie-phrase .inner {
    max-width: 600px;
}
.main-container {
    padding:0 100px;
    @include xl {
      padding: 0;
    }
}
.anchor {
    height:100px;
    @include xs {
      height:50px;
    }
    margin-bottom:15px;
}
.anchor-evts {
    height:50px;
}
