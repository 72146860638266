body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'Oleo Script';
    src: url('./fonts/OleoScript-Regular.woff2') format('woff2'),
        url('./fonts/OleoScript-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'TradeGothicLTStd-Obl';
    src: url('./fonts/TradeGothicLTStd-Obl.woff2') format('woff2'),
        url('./fonts/TradeGothicLTStd-Obl.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TradeGothicLTStd-LightObl';
    src: url('./fonts/TradeGothicLTStd-LightObl.woff2') format('woff2'),
        url('./fonts/TradeGothicLTStd-LightObl.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TradeGothicLTStd-Light';
    src: url('./fonts/TradeGothicLTStd-Light.woff2') format('woff2'),
        url('./fonts/TradeGothicLTStd-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TradeGothicLTStd-Extended';
    src: url('./fonts/TradeGothicLTStd-Extended.woff2') format('woff2'),
        url('./fonts/TradeGothicLTStd-Extended.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TradeGothicLTStd-Cn18';
    src: url('./fonts/TradeGothicLTStd-Cn18.woff2') format('woff2'),
        url('./fonts/TradeGothicLTStd-Cn18.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TradeGothicLTStd-BoldExt';
    src: url('./fonts/TradeGothicLTStd-BoldExt.woff2') format('woff2'),
        url('./fonts/TradeGothicLTStd-BoldExt.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TradeGothicLTStd-BoldObl';
    src: url('./fonts/TradeGothicLTStd-BoldObl.woff2') format('woff2'),
        url('./fonts/TradeGothicLTStd-BoldObl.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TradeGothicLTStd-Cn18Obl';
    src: url('./fonts/TradeGothicLTStd-Cn18Obl.woff2') format('woff2'),
        url('./fonts/TradeGothicLTStd-Cn18Obl.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TradeGothicLTStd-BdCn20Obl';
    src: url('./fonts/TradeGothicLTStd-BdCn20Obl.woff2') format('woff2'),
        url('./fonts/TradeGothicLTStd-BdCn20Obl.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TradeGothicLTStd-BdCn20';
    src: url('./fonts/TradeGothicLTStd-BdCn20.woff2') format('woff2'),
        url('./fonts/TradeGothicLTStd-BdCn20.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TradeGothicLTStd-Bold';
    src: url('./fonts/TradeGothicLTStd-Bold.woff2') format('woff2'),
        url('./fonts/TradeGothicLTStd-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TradeGothicLTStd';
    src: url('./fonts/TradeGothicLTStd.woff2') format('woff2'),
        url('./fonts/TradeGothicLTStd.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TradeGothicLTStd-Bd2';
    src: url('./fonts/TradeGothicLTStd-Bd2.woff2') format('woff2'),
        url('./fonts/TradeGothicLTStd-Bd2.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TradeGothicLTStd-Bd2Obl';
    src: url('./fonts/TradeGothicLTStd-Bd2Obl.woff2') format('woff2'),
        url('./fonts/TradeGothicLTStd-Bd2Obl.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
h3 {
    font-weight:normal;
}
