@import '../../utils/variables.scss';

.generique-layout {
    width:100%;
}
.generique-layout-inner {
    padding:50px;
    @include xs {
      padding:30px;
    }
    & .generique-layout-0 {
        font-family:'TradeGothicLTStd';
        color:$gris;
        font-size:30px;
        hyphens: auto;
        text-align:center;
        & .generique-texte h3 {
            color:$rouge;
        }
    }
    & .generique-16-9 {
      perspective: 500px;
      perspective-origin: 50% 50%;
      width:100%;
      padding-bottom: 56.25%;
      position: relative;
      & .cartouche-image {
        @include highlight-tile;
      }
    }
    & .generique-4-3 {
      perspective: 500px;
      perspective-origin: 50% 50%;
      width:100%;
      padding-bottom: 75%;
      position: relative;
      & .cartouche-image {
        @include highlight-tile;
      }
    }
}
