.background {
  position: absolute;
  z-index:0;
  top:0;
  left:0;
  width:100%;
  height:100%;
  min-height:150vh;
  background-position: left top;
  background-size: 100%;
  background-repeat:repeat-y;
  background-image: url('../../imgs/fond-bleu_square.jpg');
  & .bas {
    position: absolute;
    bottom:0;
    left:0;
    width:100%;
    padding-bottom: 100%;
    &>div {
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      background-position: bottom left;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url('../../imgs/bas-bleu.png');
    }
  }
  &.biocybele{
    background-image: url('../../imgs/fond-biocybele_square.jpg');
    & .bas {
      &>div {
        background-image: url('../../imgs/bas-biocybele.png');
      }
    }
  }
  &.noctambio{
    background-image: url('../../imgs/fond-noctambio_square.jpg');
    & .bas {
      &>div {
        background-image: url('../../imgs/bas-noctambio.png');
      }
    }
  }
  &.vin{
    background-image: url('../../imgs/fond-vin_square.jpg');
    & .bas {
      &>div {
        background-image: url('../../imgs/bas-vin.png');
      }
    }
  }

}
