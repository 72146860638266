@import "../../utils/variables.scss";
.encart {
    width:100%;
    background-image: url('../../imgs/encart.svg');
    background-position: top left;
    background-repeat: no-repeat;
    position:relative;
    left:-3px;
    @include xs {
      margin-top:0;
      left:-2px;
      background-size:200%;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    & h3 {
        font-family:'Oleo Script';
        font-weight:normal;
        color:#016a66;
        font-size:2em;
    }

    img {
        max-width: 100%;
    }
}
.encart.right {
    background-image: url('../../imgs/encart-d.svg');
    background-position: top right;
    left:3px;
    @include xs {
      margin-top:0;
      left:2px;
      background-size:200%;
    }
}
.encart-inner {
    & *:first-child {
        margin-top:0;
    }
    & *:last-child {
        margin-bottom:0;
    }
}
