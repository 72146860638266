@import '../../utils/variables.scss';
.ariane {
    padding: 15px 0;
    color:$gris;
    & a {
        text-decoration: none;
        color:$rouge;
    }
    & a:hover {
        color:#000;
    }
}
