@import "../../utils/variables.scss";
.header {
  position:relative;
  z-index:10;
  box-shadow: 0px 2px 2px -1px rgba(0,0,0,0.2),0px 3px 3px 0px rgba(0,0,0,0.14),0px 4px 4px 0px rgba(0,0,0,0.06);
  height: calc( 16.77vw + 97px );
  @include lg {
    height: calc( 20vw + 97px );
  }
  @include md {
    height: calc( 30vw + 70px );
  }
  @include sm {
    height: calc( 30vw + 70px );
  }
  @include xs {
    height: calc( 30vw + 48px );
  }
  &.racine {
      height: calc( 360px + 97px );
      @include md {
        height: calc( 360px + 70px );
      }
      @include sm {
        height: calc( 280px + 70px );
      }
      @include xs {
        height: calc( 250px + 48px );
      }
  }
  & .deco {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:150px;
      overflow: hidden;
  }
  & .topbar {
      position:absolute;
      bottom:0;
      z-index:1;
  }
  & .logo-container {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:360px;
      display: flex;
      justify-content: center;
      @include sm {
        height:280px;
      }
      @include xs {
        height:250px;
      }
  }
  & .logo {
      position:relative;
      align-self:center;
      height:0;
      top:-175px;
      width:350px;
      & .logo-circle {
          position:absolute;
          top:25px;
          left:25px;
          width:300px;
          height:300px;
          border-radius: 50%;
          background:#FFF;
          transform: scale(0);
      }
      & .logo-img {
          position:absolute;
          top:0;
          left:70px;
          height:350px;
          width:210px;
          z-index:0;
          background: url('../../imgs/logo.svg');
          background-position: center 15px;
          background-repeat: no-repeat;
          background-size: contain;
          transform: scale(1) translateY(0);
          @include md {
             transform: scale(0.8) translateY(0px);
          }
          @include sm {
             transform: scale(0.6) translateY(0px);
          }
          @include xs {
             transform: scale(0.6) translateY(50px);
          }
          transition: transform 0.5s;
          transform-origin: center center;
          & span {
            position:absolute;
            bottom:0;
            left:0;
            width:100%;
            text-align:center;
            font-family: 'TradeGothicLTStd-Bold';
            color:#a32f34ff;
            font-size:45px;
          }
      }
  }
  &.biocybele, &.vin, &.noctambio {
    & .logo-container {
        height:16.77vw;
        @include lg {
          height:20vw;
        }
        @include md {
          height:30vw;
        }
        @include sm {
          height:30vw;
        }
        @include xs {
          height:30vw;
        }
        & .rub-link a {
          position:absolute;
          width:100%;
          text-align:center;
          top:230px;
          font-family:'Oleo Script';
          font-weight:normal;
          color:#016a66;
          font-size:16px;
          text-decoration: none;
          & h3 {
            margin:0;
          }
        }
    }
    & .logo {
      z-index:1000;
      overflow:hidden;
      position:absolute;
      height:257px;
      left:-112px;
      top: calc( 16.77vw - 160px );
      @include lg {
        left:-125px;
        top: calc( 20vw - 148px );
        height:245px;
        width:300px;
      }
      @include md {
        left:-125px;
        top: calc( 30vw - 175px );
        height:245px;
        width:300px;
      }
      @include sm {
        left:-130px;
        top: calc( 30vw - 165px );
        height:235px;
        width:250px;
      }
      @include xs {
        left:-150px;
        top: calc( 30vw - 165px );
        height:213px;
      }
      & .logo-circle {
        transform: scale(0.65);
        @include lg {
          transform: scale(0.55);
        }
        @include md {
          transform: scale(0.55);
        }
        @include sm {
          transform: scale(0.45);
        }
        @include xs {
          transform: scale(0.3);
        }
        transition: transform 0.5s;
        transform-origin: center center;
      }
      & .logo-img {
        transform: scale(0.4) translateY(0px);
        @include lg {
          transform: scale(0.35) translateY(0px);
        }
        @include md {
          transform: scale(0.35) translateY(0px);
        }
        @include sm {
          transform: scale(0.3) translateY(0px);
        }
        @include xs {
          transform: scale(0.2) translateY(0px);
        }
      }
    }
  }
  & .deco1 {
      position:absolute;
      top:-20px;
      left:-63px;
      width:336px;
      height:148px;
      @include sm {
        top:-30px;
        left:-63px;
        width:250px;
        height:130px;
      }
      @include xs {
        top:-10px;
        left:-63px;
        width:200px;
        height:100px;
      }
      background: url('../../imgs/deco_1.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
  }
  & .deco2 {
      position:absolute;
      top:-10px;
      right:-63px;
      width:271px;
      height:138px;
      @include sm {
        top:-30px;
        right:-63px;
        width:220px;
        height:130px;
      }
      @include xs {
        top:-10px;
        right:-63px;
        width:200px;
        height:100px;
      }
      background: url('../../imgs/deco_2.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
  }
  & .line {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:10px;
      background-color: #a32f34ff;
      box-shadow: 0 0 10px #0005;
      z-index:4;
  }
  &.header-article {
      height: 210px;
      &.header-xs {
        height:48px;
      }
      & .logo-container {
        top:0;
        left:0;
        height: 0;
        width: 100%;
        z-index:3;
        display: block;
      }
      & .logo {
        overflow:hidden;
        left:-120px;
        top: -45px;
        height:255px;
        width:300px;
        @include lg {
          left:-125px;
          top: -35px;
          height:245px;
          width:300px;
        }
        @include md {
          left:-125px;
          top: -35px;
          height:245px;
          width:300px;
        }
        @include sm {
          left:-130px;
          top: -21px;
          height:231px;
          width:250px;
        }
        & .logo-circle {
          transform: scale(0.6);
          @include lg {
            transform: scale(0.55);
          }
          @include md {
            transform: scale(0.55);
          }
          @include sm {
            transform: scale(0.45);
          }
          @include xs {
            transform: scale(0.3);
          }
          transition: transform 0.5s;
          transform-origin: center center;
        }
        & .logo-img {
          transform: scale(0.4) translateY(0);
          @include lg {
            transform: scale(0.35) translateY(0px);
          }
          @include md {
            transform: scale(0.35) translateY(0px);
          }
          @include sm {
            transform: scale(0.3) translateY(0px);
          }
        }
     }
  }
  &.header-article{
    &.biocybele, &.vin, &.noctambio {
      & .logo {
        overflow:hidden;
        left:-120px;
        top: -45px;
        height:255px;
        width:300px;
        @include lg {
          left:-125px;
          top: -35px;
          height:245px;
          width:300px;
        }
        @include md {
          left:-125px;
          top: -35px;
          height:245px;
          width:300px;
        }
        @include sm {
          left:-130px;
          top: -21px;
          height:231px;
          width:250px;
        }
        & .logo-circle {
          transform: scale(0.6);
          @include lg {
            transform: scale(0.55);
          }
          @include md {
            transform: scale(0.55);
          }
          @include sm {
            transform: scale(0.45);
          }
          @include xs {
            transform: scale(0.3);
          }
          transition: transform 0.5s;
          transform-origin: center center;
        }
        & .logo-img {
          transform: scale(0.4) translateY(0px);
          @include lg {
          transform: scale(0.35) translateY(0px);
          }
          @include md {
          transform: scale(0.35) translateY(0px);
          }
          @include sm {
          transform: scale(0.3) translateY(0px);
          }
        }
      }
    }
  }
  .header-xs.header-article {
    height:0;
  }
  & .rubrique-header {
      position:absolute;
      top: 0;
      left:0;
  }
  &.small .topbar{
      position: fixed;
      bottom:none;
      top:0;
      height:50px;
      width:100%;
      & .MuiTab-root, & .MuiTab-wrapper {
          height:50px;
          font-size:20px;
          white-space: nowrap;
          @include xl {
              font-size:18px;
          }
          @include lg {
             font-size:18px;
          }
          @include md {
             font-size:16px;
             white-space: normal;
             line-height:1em;
          }
      }
      & .MuiTab-root {
        max-width:calc(20%);
        min-width:calc(10%);
      }
      & .MuiTabs-root {
        &>div{
          @include xl {
              padding-left: 0;
          }
        }
      }
  }
  &.biocybele .topbar{
    background-image: url('../../imgs/fond-biocybele_top.jpg');
  }
  &.noctambio .topbar{
    background-image: url('../../imgs/fond-noctambio_top.jpg');
  }
  &.vin .topbar{
    background-image: url('../../imgs/fond-vin_top.jpg');
  }
}
