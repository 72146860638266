@import "../../utils/variables.scss";
.topbar {
  @include md {
     & .MuiTabs-indicator {
       height:4px;
     }
  }
  & .xs-menu {
    display:flex;
    flex-direction: row-reverse;
  }
  & .xs-main-link {
      position:absolute;
      width:calc(100% - 80px);
      margin: 0 auto;
      height:48px;
      left:40px;
      top:0;
      line-height:48px;
      text-align: center;
      font-family:'Oleo Script';
      font-weight:normal;
      text-transform:none;
      font-size:24px;
      overflow:hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color:$h1;
      & a {
        color:$h1;
      }
      opacity:0;
      transition: opacity 0.5s;
      .small & {
        opacity:1;
      }
  }
  & .logo-img {
        position:absolute;
        bottom:calc(50% - 20px);
        left:5px;
        height:40px;
        width:40px;
        z-index:0;
        background: url('../../imgs/logo.svg');
        background-position: center 9%;
        background-repeat: no-repeat;
        background-size: 108%;
        //border-radius: 20px;
        transform: scale(1) translateY(0);
        opacity:0;
        transition: opacity 0.5s, bottom 0.5s;
        .small & {
          bottom:5px;
          opacity:1;
        }
    }
}
.topbar .MuiTab-root, .topbar .MuiTab-wrapper {
    //transition: height 0.2s;
    font-family:'Oleo Script';
    font-weight:normal;
    text-transform:none;
    color:#016a66;
    line-height: normal;
    height:98px;
    font-size:30px;
    @include xl {
       font-size:25px;
    }
    @include lg {
       font-size:20px;
    }
    @include md {
       font-size:18px;
       line-height: 1.1em;
       height:70px;
    }
    @include sm {
       font-size:16px;
       line-height: 1.1em;
       height:70px;
    }
}
.topbar .MuiTab-root {
    max-width:20%;
    min-width:10%;
}
.topbar.MuiPaper-root {
  @include lg {
     padding-left:120px;
  }
  @include sm {
     padding-left:100px;
  }
  .home & {
      padding-left:0;
  }
  .small & {
      padding-left:0;
  }
}
.topbar .sousmenus {
  position:relative;
  @include lg {
     left:-120px;
  }
  @include sm {
     left:-100px;
  }
  .home & {
      left:0;
  }
  .small & {
      left:0;
  }
  width:100%;
  font-family:'Oleo Script';
  font-weight:normal;
  text-transform:none;
}
.topbar .sousmenu {
  position:absolute;
  top:0;
  left:0;
  z-index:1000;
  display:none;
}
.topbar .sousmenu.show {
  display:block;
}
.topbar .sousmenu {
  list-style: none;
  padding:0px 0;
  margin:0;
  background-color: #e1f0f7;
}
.topbar .sousmenu li {
  padding:5px 15px;
  @include md {
    padding:2px 15px;
    line-height:1.1em;
  }

  &:hover {
    background-color: #0001;
  }
}
.topbar .sousmenu li a {
  display:block;
  width:100%;
  text-decoration: none;
  color: inherit;
  @include md {
     font-size:16px;
  }
}
.topbar.MuiAppBar-colorPrimary {
    //transition: height 0.2s;
    background: url('../../imgs/fond-bleu_top.jpg');
    @include xs {
       background: none;
       .small & {
          background: url('../../imgs/fond-bleu_top.jpg');
          .biocybele & {
            background-image: url('../../imgs/fond-biocybele_top.jpg');
          }
          .noctambio & {
            background-image: url('../../imgs/fond-noctambio_top.jpg');
          }
          .vin & {
            background-image: url('../../imgs/fond-vin_top.jpg');
          }

       }
       box-shadow: 0px 2px 2px -1px rgba(0,0,0,0.2),0px 3px 3px 0px rgba(0,0,0,0.14),0px 4px 4px 0px rgba(0,0,0,0.06);
    }
}
.topbar .MuiTabs-root {
    background: linear-gradient(#ffff 0%, #fff0 50%);
}
.MuiMenuItem-root.menu {
  font-family:'Oleo Script';
  font-weight:normal;
  color:$h1;
  line-height:1em;
  font-size:20px;
  width:100%;
  white-space: normal;
  & a {
    color:$h1;
  }
  &.ss-menu {
    font-size:16px;
    min-height:20px;
    padding:5px 5px 5px 32px;
    & a {
      color:$h2;
    }
  }
  &.close {
    position:absolute;
    top:0;
    right:0;
    width:2em;
    height:2em;
    min-height:2em;
    z-index:2;
    border-radius: 6px;
    &>span {
      position:absolute;
      top:0;
      right:0;
      padding:8px;
    }
  }
}
