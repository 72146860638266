@import "../../utils/variables.scss";
.cartouche-image-rond {
    position:relative;
    width:100%;
    padding-bottom:100%;
}
.cartouche-image-rond-inner {
    position:absolute;
    height:100%;
    width:100%;
    top:0;
    left:0;
    clip-path: circle(50%);
    background: #FFFA;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.cartouche-image-rond-fond {
    position:absolute;
    height:calc( 100% + 20px );
    width:calc( 100% + 20px );
    top:-10px;
    clip-path: circle(50%);
    background: $eau;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.cartouche-image-rond-fond-gauche {
    left:-2px;
}
.cartouche-image-rond-fond-droit {
    right:-2px;
}
