@import "../../utils/variables.scss";
.actualites {
    & h1 {
        font-family:'Oleo Script';
        color:$vert;
        font-size:50px;
        text-align:center;
        font-weight: normal;
        margin-bottom: 0
    }
    & .prevnext {
      font-family:'Oleo Script';
      color:$vert;
      font-size:50px;
      line-height:20px;
      position:relative;
      top:7px;
      cursor: pointer;
      &:hover {
        color:$rouge;
      }
    }
    & .pagination {
      line-height:20px;
      text-align:right;
      user-select: none;
      margin-bottom:30px;
    }
}
.actualites-container {
    padding: 0 100px;
    @include xl {
      padding: 0;
    }
}
.actualites-header {
    position:relative;
    width:100%;
    padding-bottom:16.77%;
}
.actualites-header-inner {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display:flex;
    justify-content: center;
    background-color: #6662;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    & h3 {
        font-family:'Oleo Script';
        font-size:80px;
        margin:0;
        align-self:center;
        font-weight: normal;
        @include xl {
          font-size:70px;
        }
        @include lg {
          font-size:60px;
        }
        @include md {
          font-size:50px;
        }
    }
}
.actualites-evt{
    background-color:#FFF;
    min-height:100%;
    padding:15px;
    border-radius:4px;
    box-shadow: -6px 6px 5px #00000035;
    position:relative;
    & .actualites-evt-type {
        font-family:'Oleo Script';
        font-weight:normal;
        font-size:16px;
        position:absolute;
        top:-20px;
        left:5px;
        height:30px;
        border-radius:15px;
        padding:0 10px;
        line-height:30px;
        color:#FFF;
        box-shadow: -3px 3px 5px #0005;
    }
    & .actualites-evt-plus {
        font-family:'Oleo Script';
        font-weight:normal;
        font-size:16px;
        position:absolute;
        bottom:5px;
        right:5px;
        height:30px;
        padding:0 10px;
        line-height:30px;
    }
    & .actualites-evt-bandeau {
        position:relative;
        width:calc(100% + 30px);
        padding-bottom:33%;
        &>img {
            position:absolute;
            border-radius:4px 4px 0 0;
            top:-15px;
            left:-15px;
            width:100%;
            height:100%;
        }
    }
    .actualites-evt-text {
      line-height: 1.25em;
      @include line-clamp(10, 1.25em);
    }
}
.MuiGrid-item.actualites-evt-container {
    margin-bottom:50px;
}
.actualites-evt, .actualite {
    & h3 {
        font-family:'Oleo Script';
        font-weight:normal;
        font-size:30px;
        line-height:1em;
        margin:0;
        margin-bottom:5px;
    }
    & .actualites-evt-dates {
        font-family:'TradeGothicLTStd-Bold';
        font-size:16px;
        line-height: 1;
        margin: 0;
    }
}
.actualites-evt h3 {
  @include line-clamp(3, 1em);
}
.actualite .actualites-evt-dates {
    padding-left:30px;
}
.actualites-filters {
  margin-bottom:50px;
  text-align:center;
  user-select: none;
  & span.actualites-filter {
      display:inline-block;
      cursor:pointer;
      font-family:'Oleo Script';
      font-weight:normal;
      font-size:16px;
      border-radius:15px;
      padding:0 10px;
      line-height:2em;
      color:#FFF;
      box-shadow: -3px 3px 5px #0005;
      margin: 5px;
      opacity:0.5;
      transition:opacity 0.5s;
      &.active {
        opacity:1;
      }
      @include xs {
          padding:0 10px;
          line-height:1.5em;
      }
  }
}
.actualite {
    font-family:'TradeGothicLTStd';
    background-image: url('../../imgs/encart.svg');
    position:relative;
    left:-3px;
    background-position: left top;
    background-repeat: no-repeat;
    margin-top:50px;
    padding:50px;
    @include xs {
        margin-top:0px;
        padding:20px;
        left:-2px;
        background-size:200%;
    }
    &>h1 {
        line-height:1em;
        font-family:'Oleo Script';
        color:#016a66;
        font-size:45px;
        margin-top:0;
        font-weight: normal;
        @include xl {
          font-size:40px;
        }
        @include md {
          font-size:35px;
        }
        @include sm {
          font-size:30px;
        }
    }
}
.actualite-evt-bandeau {
    position:relative;
    width:100%;
    padding-bottom:16.77%;
    &>img {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }
}
.actualite img {
    max-width:100%;
    height: auto;
}
.actu-footer {
    font-family:'Oleo Script';
    font-weight: normal;
    font-size:24px;
    text-align: right;
    & a:hover {
      color:$rouge;
    }
}
