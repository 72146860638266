@import "../../utils/variables.scss";
.article {
    font-family:'TradeGothicLTStd';
    background-image: url('../../imgs/encart.svg');
    position:relative;
    left:-3px;
    background-position: left top;
    background-repeat: no-repeat;
    margin-top:50px;
    padding:50px;
    overflow-x:hidden;
    @include xs {
      margin-top:0;
      padding:20px;
      left:-2px;
      background-size:200%;
    }
    &>h1 {
        font-family:'Oleo Script';
        color:#016a66;
        font-size:45px;
        line-height:1em;
        margin-top:0;
        font-weight: normal;
        @include xl {
          font-size:40px;
        }
        @include md {
          font-size:35px;
        }
        @include sm {
          font-size:30px;
        }
    }
    & .bloc {
      padding:0 100px;
      @include xl {
        padding:0 50px;
      }
      @include md {
        padding:0;
      }
    }
}
.article img {
    max-width:100%;
    height: auto;
}
