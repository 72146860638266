@import "../../utils/variables.scss";
.evenements {
  background-color:#284456;
  & h3 {
    font-family:'Oleo Script';
    font-weight:normal;
    font-size:45px;
    text-align: center;
    color:$eauclair;
    @include xs {
        font-size:30px;
    }
  }
}
.evenements-desc {
    text-align: center;
    color:$eauclair;
    margin-bottom:50px;
    font-family: 'TradeGothicLTStd';
    font-size:20px;
    padding:0 15px;
}
.evenements-fest {
    text-align: center;
    color:#FFF;
    margin-bottom:50px;
    & h3 {
      font-family:'Oleo Script';
      font-weight:normal;
      font-size:30px;
      text-align: center;
      color:$eauclair;
      margin-bottom: 0px;
    }
    & .evenements-fest-pitch {
        font-family:'Oleo Script';
        font-weight:normal;
        font-size:20px;
        color:$eau;
        padding:0 30px;
    }
    & .evenements-fest-desc {
        font-size:20px;
        color:#FFF;
        padding:0 30px;
    }
}
.evenements-fest-visuel {
    position:relative;
    width:40%;
    margin:0 auto;
}
.anchor-evts {
    height:0;
}
