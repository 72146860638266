@import "../../utils/variables.scss";
.carte {
  height:100vh;
  .carte-inner {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    .carte-map {
      position:relative;
      z-index:0;
      width:100%;
      height:100%;
      & h3 {
        font-weight:bold;
        color:$vert;
        margin:5px 0;
        border-top: 1px solid #0002;
      }
      & .leaflet-top {
        top:100px;
      }
    }
  }
}
.pro-tile-backdrop {
  position:fixed;
  z-index:10000;
  top:0;
  left:0;
  width:100%;
  height:100%;
  display:flex;
  align-items: center;
  justify-content: center;
  background-color: #FFF8;
  & .pro-tile {
    width:1000px;
    max-height: calc(100% - 60px);
    max-width: calc(100% - 60px);
    background-color: #FFF;
    box-shadow: 0 0 20px #0008;
    overflow-y:auto;
    & .pro-diaporama {
      width:100%;
      padding-bottom:40%;
      position:relative;
      background-color:#FFF8;
      & .swiper-container {
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        & .swiper-slide {
          display:flex;
          align-items:center;
          & img {
            align-self:center;
            width:100%;
            height:100%;
            object-fit: cover;
          }
        }
      }
      & .swiper-button-prev, & .swiper-button-next, & .swiper-pagination, & .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
        opacity:1;
        transition: opacity 0.5s;
      }
      & .swiper-button-prev, & .swiper-button-next {
        color: $blanc;
        &:after {
          text-shadow: 0px 0px 10px #0008;
        }
      }
      & .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
        opacity:0;
        color: #FFF;
      }
      & .swiper-pagination-bullet {
        opacity:1;
        background-color: #FFF;
        box-shadow: 0 0 10px #0005;
      }
      & .swiper-pagination-bullet-active {
        background-color: $vert;
      }

    }
    & .pro-content {
      font-family:'TradeGothicLTStd';
      padding:30px;
      & h3 {
        color: $vert;
        font-size:40px;
        margin:0;
        & span.nom {
          text-transform: uppercase;
        }
      }
      & h4 {
        color: $vert;
        font-size:30px;
        margin:0;
      }
      & .pro-content-adresse {
        font-family:'TradeGothicLTStd-Bd2';
        font-size:20px;
        color: $orange;
        white-space: pre-line;
        margin:15px 0;
      }
      & .pro-content-descriptif {
        font-size:18px;
        white-space: pre-line;
        margin:15px 0;
      }
    }
  }
}
