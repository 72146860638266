@import '../../utils/variables.scss';
.engager {
    margin-bottom:100px;
    & .engager-titre {
      font-family:'Oleo Script';
      font-weight:normal;
      font-size:60px;
      text-align: center;
      color:$vert;
    }
    & a {
        text-decoration: none;
    }
}
.engager-art-item {
    perspective: 500px;
    perspective-origin: 50% 50%;
    & .engager-art {
        text-align: center;
        color:#FFF;
        background-color: $vert;
        padding:15px;
        font-size:40px;
        height:3em;
        display: flex;
        justify-content: center;
        & h3 {
            font-size:30px;
            text-align: center;
            color:$blanc;
            align-self: center;
        }
        @include highlight-tile;
    }
}
.engager-art-item-1 {
    & .engager-art {
        background-color: $orange;
    }
}
