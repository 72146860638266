@import "../../utils/variables.scss";
.rubrique img {
    width:100%;
    height: auto;
}
.rubrique-container {
    padding: 0 100px;
    @include xl {
      padding: 0;
    }
}
.rubrique-header {
    position:relative;
    width:100%;
    padding-bottom:16.77vw;
    @include lg {
        padding-bottom:20vw;
    }
    @include md {
        padding-bottom:30vw;
    }
}
.rubrique-header-inner {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display:flex;
    justify-content: center;
    background-color: #6662;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    & h3 {
        font-family:'Oleo Script';
        font-weight:normal;
        font-size:80px;
        text-align: center;
        line-height: 1em;
        margin:0;
        padding:0 30px;
        align-self:center;
        @include xl {
          font-size:70px;
        }
        @include lg {
          font-size:60px;
        }
        @include md {
          font-size:50px;
        }
        @include sm {
          font-size:40px;
        }
    }
}
