@import '../../utils/variables.scss';

.contact-layout {
    width:100%;
}
.contact-layout-inner {
    padding:50px;
    @include xs {
      padding:30px;
    }
    & .contact-layout-0 {
        font-family:'TradeGothicLTStd';
        color:$gris;
        font-size:30px;
        hyphens: auto;
        text-align:center;
        & .contact-texte h3 {
            color:$rouge;
        }
    }
    & .contact-medium {
      perspective: 500px;
      perspective-origin: 50% 50%;
      width:100%;
      padding-bottom: 75%;
      position: relative;
      & .cartouche-image {
        box-shadow: -6px 6px 5px #00000035;
      }
    }
}
