@import '../../utils/variables.scss';

.asso-layout {
    width:100%;
    padding-bottom: 46%;
    position:relative;
}
.asso-layout-inner {
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    perspective: 500px;
    perspective-origin: 75% 50%;
}
.asso-layout-0 {
    position:absolute;
    top:10%;
    left:5%;
    width:30%;
    height:80%;
    font-family:'TradeGothicLTStd';
    color:$gris;
    font-size:30px;
    hyphens: auto;
    text-align:center;
}
.asso-layout-1 {
    position:absolute;
    top:10%;
    left:40%;
    width:25%;
    height:55%;
    @include highlight-tile;
}
.asso-layout-2 {
    position:absolute;
    top:10%;
    left:70%;
    width:25%;
    height:44%;
    @include highlight-tile;
}
.asso-layout-3 {
    position:absolute;
    top:84%;
    left:35%;
    width:60%;
    height:32%;
}
.cartouche-carte {
    width:100%;
    height:100%;
    position:absolute;
    left:0;
    top:0;
}
.dessin-carte {
    background:url('./dessin_carte.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width:47%;
    height:300%;
    position:absolute;
    top:-130%;
    right:0;
}
.cartouche-carte-textes {
    width:60%;
    height:135%;
    bottom:-80px;
    left:0;
    text-align:right;
    & .shape-mask {
      float: right;
      width:100%;
      height:100%;
      shape-outside: polygon(103.11% 64.6%, 94.49% 47.19%, 89.99% 33.37%, 87.14% 18.62%, 85.15% -7.76%, 103.29% -15.33%);
    }
}
.cartouche-carte-texte1 {
    font-family:'Oleo Script';
    font-weight:normal;
    color:#a32f34ff;
    font-size:50px;
    line-height:1;
}
.cartouche-carte-texte2 {
    font-family:'Oleo Script';
    font-weight:normal;
    color:#87a464ff;
    font-size:36px;
}
