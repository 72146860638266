$rouge: #A32F34;
$vert: #016A66;
$gris: #284456;
$blanc: #FFFFFF;
$eau: #91c4b9;
$eauclair: #cee5f4;
$orange: #d9872b;
$h1: #006a65;
$h1Contrast: #c5e8f6;
$h2: #75c5bb;
$h2Bis: #24b4a2;
$h3: #044257;
$text:#044257;
$textContrast:#c5e8f6;

@mixin highlight-tile {
  transform: translateZ(0px);
  box-shadow: 0 0 10px #0003;
  transition: transform 0.5s, box-shadow 0.5s;
  &:hover {
      box-shadow: 0 0 10px #0008;
      transform: translateZ(5px);
  }
}
// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// Large tablets and desktops
$screen-xxl-min: 1600px;

// XSmall devices
@mixin xs {
   @media (max-width: #{$screen-sm-min}) {
       @content;
   }
}

// Small devices
@mixin sm {
    @media (max-width: #{$screen-md-min}) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media (max-width: #{$screen-lg-min}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (max-width: #{$screen-xl-min}) {
       @content;
    }
}

// Extra large devices
@mixin xl {
    @media (max-width: #{$screen-xxl-min}) {
       @content;
    }
}
//typo
@mixin typoH1 {
    @media (max-width: #{$screen-xxl-min}) {
       @content;
    }
}
@mixin line-clamp($lines, $line-height) {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    max-height: (($lines+1) * $line-height);
    padding-bottom: 0.05em;
    text-overflow: ellipsis;
    overflow: hidden;
}
